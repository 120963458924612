const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '6092721d2a3278f52e60f4d6',
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    enableExperimentalBlocks: true,
    metadatas: {
      name: 'La matinale Auchan',
      colorPrimary: '#e30613',
      colorAccent: '#69605d',
      supportEmailAddress: 'support@lamatinaleauchan.fr',
    },
    analytics: {
      matomo: '9',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '61814ad22d216882c1518434',
            i18n: {
              fr: {
                title: 'Replay des Matinales',
                description: `Ecouter le replay de vos émissions quotidiennes`,
              },
            },
            heading: {
              color: '#E30613',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '61814ad81b491a5ce6f58030',
            i18n: {
              fr: {
                title: 'Chroniques',
                description: `Les chroniques de la Matinale directement disponibles en replay`,
              },
            },
            heading: {
              color: '#ee7203 ',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
            filters: {
              type: 'solo',
              soloTagId: '61814ae52d2168983f518435',
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
