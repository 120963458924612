var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-vinci-form-modal': true,
      'w-vinci-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close-thick")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/replaymatinalemobile.png')
          : require('~/assets/images/replaymatinale.png'),"alt":"image popup","max-height":_vm.$voicer.isMobile ? 368 : undefined},on:{"click":function($event){return _vm.handleOpen(
          'https://www.lamatinaleauchan.fr/listen/658c3c2a7bf2efec370c9959'
        )}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }