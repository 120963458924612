<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-vinci-form-modal': true,
        'w-vinci-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="black">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/replaymatinalemobile.png')
            : require('~/assets/images/replaymatinale.png')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 368 : undefined"
        @click="
          handleOpen(
            'https://www.lamatinaleauchan.fr/listen/658c3c2a7bf2efec370c9959'
          )
        "
      />
    </v-card>
  </v-dialog>
</template>
<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>
