<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container class="pa-0">
      <v-sheet
        ref="sheet"
        color="white"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
        :style="{ ...generateFunkyStyle('boxShadow', 0) }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Vous souhaitez nous parler d'un projet, nous présenter une
            initiative ou bien simplement laisser une dédicace à votre collègue
            préféré ? Contactez-nous à
            <a href="mailto:contact@lamatinaleauchan.fr"
              >contact@lamatinaleauchan.fr</a
            >
            ou par téléphone au <a href="tel:+33173608786">01 73 60 87 86</a>
          </div>
          <v-btn
            ref="btn"
            :style="{ ...generateFunkyStyle('backgroundColor', 0) }"
            href="/listen/60b7a92c5ca4044c52260798"
            rounded
            depressed
            class="ma-2 white--text"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-broadcast </v-icon>
            Ecouter le direct de 6h à 8h
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
    <w-block name="cta" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
  mounted() {
    const duration = 12000
    const btn = this.$refs.btn
    const sheet = this.$refs.sheet

    if (btn) {
      const btnElement = btn.$el

      btnElement.animate(
        [
          {
            ...this.generateFunkyStyle('backgroundColor', 0),
            offset: 0.1,
          },
          {
            ...this.generateFunkyStyle('backgroundColor', 1),
            offset: 0.25,
          },
          {
            ...this.generateFunkyStyle('backgroundColor', 2),
            offset: 0.5,
          },
          {
            ...this.generateFunkyStyle('backgroundColor', 3),
            offset: 0.85,
          },
        ],
        {
          duration,
          iterations: Infinity,
        }
      )
    }

    if (sheet) {
      const sheetElement = sheet.$el

      sheetElement.animate(
        [
          {
            ...this.generateFunkyStyle('boxShadow', 0),
            offset: 0.1,
          },
          {
            ...this.generateFunkyStyle('boxShadow', 1),
            offset: 0.25,
          },
          {
            ...this.generateFunkyStyle('boxShadow', 2),
            offset: 0.5,
          },
          {
            ...this.generateFunkyStyle('boxShadow', 3),
            offset: 0.85,
          },
        ],
        {
          duration,
          iterations: Infinity,
        }
      )
    }
  },
  methods: {
    generateFunkyStyle(prop, factor) {
      const colors = [
        this.$metadatas.colorPrimary,
        '#ee7203',
        '#E30613',
        '#ee7203',
      ]

      const hexToRgbA = (hex) => {
        let c
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('')
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
          }
          c = '0x' + c.join('')

          return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
        }
        throw new Error('Bad Hex:' + hex)
      }

      const generateBoxShadow = () => {
        const rgb = hexToRgbA(colors[factor])

        const str = ({ alpha, x, y }) =>
          `rgba(${rgb.join(',')},${alpha}) 0 ${x}px ${y}px`

        return [
          str({ alpha: 0.4, x: 5, y: 5 }),
          str({ alpha: 0.3, x: 10, y: 10 }),
          str({ alpha: 0.2, x: 15, y: 15 }),
          str({ alpha: 0.1, x: 20, y: 20 }),
          str({ alpha: 0.05, x: 25, y: 25 }),
        ].join(', ')
      }

      const generateBackgroundColor = () => {
        return `${colors[factor]}`
      }

      const generateOutput = () => {
        switch (prop) {
          case 'backgroundColor':
            return generateBackgroundColor()
          case 'boxShadow':
            return generateBoxShadow()
        }
      }

      return {
        [prop]: generateOutput(),
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
</style>
